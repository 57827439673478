import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import {
  finishSoftskillsUserCourse,
  finishSoftskillsUserCourseClean,
  getSoftskillsCourse,
  getSoftskillsUserCourses,
  getUser,
  startSoftskillsUserCourse,
  startSoftskillsUserCourseClean,
} from "store/actions"
import UploadCourseImageModal from "components/SoftSkills/CourseUsers/UploadCourseImageModal"
import UploadCourseTextModal from "components/SoftSkills/CourseUsers/UploadCourseTextModal"
import SweetAlert from "react-bootstrap-sweetalert"
import SoftskillsProfileBox from "components/SoftSkills/Users/ProfileBox"
import moment from "moment"
import {
  reviewSoftskillCourseUser,
  reviewSoftskillCourseUserClean,
} from "store/softskills_courses/review/actions"

const SoftskillsUserCourseReview = props => {
  const { courseId, userId } = useParams()

  const [showUploadImage, setShowUploadImage] = useState(false)
  const [showUploadText, setShowUploadText] = useState(false)
  const [showFinishAlert, setShowFinishAlert] = useState(false)
  const [currentAbility, setCurrentAbility] = useState(false)
  const [comments, setComments] = useState("")
  const [error, setError] = useState(false)
  const [approval, setApproval] = useState(undefined)
  const [reviewAlert, setReviewAlert] = useState(false)

  const dispatch = useDispatch()

  const {
    whoIam,
    course,
    courses,
    loading,
    courseUser,
    courseUserLoading,
    finishLoading,
    finishDone,
    user,
    reviewLoading,
    reviewDone,
  } = useSelector(store => ({
    whoIam: store.ProfileNew.user,
    user: store.UserEdit.user,
    courses: store.SoftskillsUserCoursesList.courses,
    course: store.SoftskillCourseGet.course,
    loading: store.SoftskillCourseGet.loading,
    courseUser: store.SoftskillsUserCourseStart.course,
    courseUserLoading: store.SoftskillsUserCourseStart.loading,
    finishLoading: store.SoftskillsUserCourseFinish.loading,
    finishDone: store.SoftskillsUserCourseFinish.done,
    reviewLoading: store.SoftskillCourseUserReview.loading,
    reviewDone: store.SoftskillCourseUserReview.done,
  }))

  const statusId = courseUser !== undefined ? courseUser.statusId : 0
  const started = courseUser !== undefined
  const courseStart =
    courseUser !== undefined ? courseUser.createdAt : undefined
  const finished = courseUser !== undefined ? courseUser.statusId === 2 : false
  const approved = courseUser !== undefined ? courseUser.statusId === 3 : false
  const courseEnd = courseUser !== undefined ? courseUser.finishedAt : undefined

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getSoftskillsCourse(courseId))
      dispatch(getUser(userId))
      dispatch(startSoftskillsUserCourse({ courseId, userId }))
      return () => {
        dispatch(startSoftskillsUserCourseClean())
      }
    }
  }, [courseId])

  useEffect(() => {
    if (courses.length === 0) {
      dispatch(getSoftskillsUserCourses(userId))
    }
  }, [])

  useEffect(() => {
    courses.forEach(ability => {
      if (ability.current === true) {
        const exists = ability.courses.some(
          c => c.courseId === parseInt(courseId)
        )
        if (exists && !currentAbility) {
          setCurrentAbility(true)
        }
      }
    })
  }, [courses])

  const getModality = modalityId => {
    if (modalityId == 1) return "Online Course"
    if (modalityId == 2) return "Material"
    if (modalityId == 3) return "Playlist"
    if (modalityId == 4) return "Presencial"
    if (modalityId == 5) return "External content"
  }

  const getLanguage = languageId => {
    if (languageId == 1) return "Español"
    if (languageId == 2) return "Inglés"
    if (languageId == 3) return "Portugués"
    if (languageId == 4) return "Alemán"
  }

  const handleFinishCourse = () => {
    if (course?.imageUpload === true) {
      setShowUploadImage(true)
    }
    if (course?.openText === true) {
      setShowUploadText(true)
    }
  }

  const finishCourseWithImage = image => {
    dispatch(
      finishSoftskillsUserCourse({
        courseId: parseInt(courseId),
        userId,
        image,
      })
    )
  }

  const finishCourseWithText = text => {
    dispatch(
      finishSoftskillsUserCourse({ courseId: parseInt(courseId), userId, text })
    )
  }

  const handleApprove = approved => {
    if (comments === "") {
      setError(true)
      return
    }
    setApproval(approval)
    dispatch(
      reviewSoftskillCourseUser({
        courseId: parseInt(courseId),
        userId: parseInt(userId),
        comments: comments,
        status: approved ? 3 : 1,
      })
    )
  }

  if (finishDone) {
    dispatch(finishSoftskillsUserCourseClean())
    dispatch(getSoftskillsCourse(courseId))
    dispatch(getSoftskillsUserCourses(userId))
    dispatch(startSoftskillsUserCourse({ courseId, userId }))
    setShowFinishAlert(true)
  }

  if (reviewDone) {
    dispatch(reviewSoftskillCourseUserClean())
    dispatch(getSoftskillsCourse(courseId))
    dispatch(getSoftskillsUserCourses(userId))
    dispatch(startSoftskillsUserCourse({ courseId, userId }))
    setReviewAlert(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Detalle de curso {course?.courseName ?? ""} | Soft Skills
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Detalle del curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "my-course",
                    title: course?.courseName ?? "Detalle de curso",
                  },
                ]}
              />
            </Col>
          </Row>
          {course !== undefined && courseUser !== undefined && (
            <Row>
              <Col className="col-6">
                {user !== undefined && (
                  <SoftskillsProfileBox
                    user={user}
                    edit={false}
                    updateInfo={() => {
                      dispatch(getUser(userId))
                    }}
                  />
                )}
              </Col>
              <Col className="col-6">
                <Card>
                  <CardBody>
                    {approved ? (
                      <h1>
                        <span
                          className="badge bg-pill bg-success pt-3 pl-2 pr-2 pb-3"
                          style={{ display: "block" }}
                        >
                          Completado
                        </span>
                      </h1>
                    ) : finished ? (
                      <Fragment>
                        <h1>
                          <span
                            className="badge bg-pill bg-warning pt-3 pb-3 pl-2 pr-2"
                            style={{ display: "block" }}
                          >
                            En revisión
                          </span>
                        </h1>
                        <p className="text-center mb-0 mt-2">
                          Completado el{" "}
                          {moment(courseEnd)
                            .locale("es")
                            .format("DD/MM/YYYY HH:mm [hrs.]")}
                        </p>
                      </Fragment>
                    ) : (
                      <h1>
                        <span
                          className="badge bg-pill bg-secondary pt-2 pl-2 pr-2"
                          style={{ display: "block" }}
                        >
                          Pendiente de completar
                        </span>
                      </h1>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <img src={course.image} className="img-fluid" />
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={9}>
                            <h4 className="text-secondary">
                              {course.ability?.abilityName ?? ""}
                            </h4>
                            <h2 className="text-primary">
                              {course.courseName ?? ""}
                            </h2>
                            <hr />
                            <p>
                              <strong>Modalidad:</strong>{" "}
                              {getModality(course.modalityId)}
                            </p>
                            <p>
                              <strong>Idioma:</strong>{" "}
                              {getLanguage(course.languageId)}
                            </p>
                            <p>
                              <strong>
                                Días límite para finalizar el curso:
                              </strong>{" "}
                              {course.limitDays}
                            </p>
                            <p>
                              <strong>Duración estimada del curso:</strong>{" "}
                              {course.duration} minutos
                            </p>
                            <p>
                              <strong>Tipo de evidencia:</strong>{" "}
                              {course?.imageUpload === true
                                ? "Archivo"
                                : course?.openText === true
                                ? "Texto"
                                : ""}
                            </p>
                          </Col>
                          <Col md={3}></Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <p>
                              <strong>Objetivo del curso</strong>
                            </p>
                            <p>{course.description}</p>
                            <p className="mt-2">
                              <strong>Link para ingresar:</strong>{" "}
                              <a
                                href={course.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {course.link}
                              </a>
                            </p>
                          </Col>
                          {/*<Col md={3}>
                            {finished || !currentAbility ? (
                              <></>
                            ) : (
                              <button
                                onClick={handleFinishCourse}
                                type="button"
                                className="btn btn-primary btn-block btn-xl"
                              >
                                <i className="fa fa-check"></i> Completar curso
                              </button>
                            )}
                          </Col>*/}
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    {statusId === 2 && (
                      <Row>
                        <Col md={12}>
                          <h2>Valoración</h2>
                          <h3>
                            Tipo de evidencia:{" "}
                            {course?.imageUpload === true
                              ? "Archivo"
                              : course?.openText === true
                              ? "Texto"
                              : ""}
                          </h3>
                          {course?.imageUpload === true && (
                            <Fragment>
                              <p>
                                Dar clic en el archivo para poder visualizar o
                                descargarlo y validarlo
                              </p>
                              <a
                                className="btn btn-primary"
                                href={courseUser.image}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-download"></i> Ver o
                                descargar archivo
                              </a>
                            </Fragment>
                          )}
                          {course?.openText === true && (
                            <Fragment>
                              <p>
                                Revise los comentarios ingresados por el usuario
                              </p>
                              <small>
                                Fecha de evidencia:{" "}
                                {moment(courseEnd)
                                  .locale("es")
                                  .format("DD/MM/YYYY HH:mm [hrs.]")}
                              </small>
                              <div>{courseUser.text}</div>
                            </Fragment>
                          )}
                          <p className="mt-4">Agregar comentarios:</p>
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Comentarios"
                            onChange={e => {
                              setComments(e.target.value)
                            }}
                          ></textarea>
                          <div className="d-flex justify-content-center mt-4">
                            <button
                              className="btn btn-success pr-2 btn-lg"
                              onClick={() => handleApprove(true)}
                            >
                              <i className="fa fa-check"></i> Aprobar evidencia
                            </button>
                            <div style={{ width: "16px" }}></div>
                            <button
                              className="btn btn-danger pr-2 btn-lg"
                              onClick={() => handleApprove(false)}
                            >
                              <i className="fa fa-times"></i> No aprobar
                              evidencia
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {showUploadImage && (
        <UploadCourseImageModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={image => {
            setShowUploadImage(false)
            finishCourseWithImage(image)
          }}
        />
      )}
      {showUploadText && (
        <UploadCourseTextModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={text => {
            setShowUploadText(false)
            finishCourseWithText(text)
          }}
        />
      )}
      {showFinishAlert && (
        <SweetAlert
          title={`Curso completado`}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowFinishAlert(false)
          }}
        >
          Su evidencia ha sido recibida y enviada a validación.
        </SweetAlert>
      )}
      {error && (
        <SweetAlert
          title={`No ha agregado comentarios`}
          warning
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setError(false)
          }}
        >
          Por favor agregue los comentarios de la revisión.
        </SweetAlert>
      )}
      {reviewAlert && (
        <SweetAlert
          title={approved ? `Evidencia aprobada` : `Evidencia no aprobada`}
          confirmBtnBsStyle="primary"
          confirmBtnText="Finalizar"
          onConfirm={() => {
            setReviewAlert(false)
          }}
        >
          {approved ? (
            <Fragment>
              <p>
                El curso ha sido aprobado y por lo tanto completado por el
                usuario.
              </p>
              <p>
                Se le notificará y enviará la encuesta para que la conteste, una
                vez que lo haga, se le notificará a usted para que la pueda
                revisar.
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <p>
                El curso NO ha sido aprobado y por lo tanto el usuario deberá
                subir evidencia correcta
              </p>
              <p>
                Se le notificará al usuario para que haga los ajustes, por lo
                pronto seguirá como curso pendiente.
              </p>
            </Fragment>
          )}
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

export default SoftskillsUserCourseReview
