import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import ReactDragListView from "react-drag-listview/lib/index.js"
import {
  editSoftskillAbilityUserPosition,
  editSoftskillAbilityUserPositionClean,
  getSoftskillsAbilities,
  getSoftskillsUserProgress,
  getUser,
  removeSoftskillAbilityUser,
  removeSoftskillAbilityUserClean,
} from "store/actions"
import AbilitiesForUserModal from "components/SoftSkills/Users/AbilitiesForUserModal"
import SweetAlert from "react-bootstrap-sweetalert"
import SoftskillsProfileBox from "components/SoftSkills/Users/ProfileBox"
import { getAbilityStatus, getIndicator } from "../Helpers/Helpers"

const SoftskillsUserDetail = props => {
  const { userId } = useParams()
  const dispatch = useDispatch()

  const [showAddAbilitiesModal, setShowAddAbilitiesModal] = useState(false)
  const [data, setData] = useState([])
  const [removeAbility, setRemoveAbility] = useState(0)
  const [showAbilityDetail, setShowAbilityDetail] = useState(0)
  const [abilityProgress, setAbilityProgress] = useState([])

  const {
    user,
    getUserLoading,
    getUserDone,
    editUserLoading,
    editUserError,
    editUserDone,
    whoIAm,
    abilities,
    userPositionDone,
    removeDone,
    profile,
    progress,
  } = useSelector(state => ({
    user: state.UserEdit.user,
    getUserLoading: state.UserEdit.loading,
    getUserDone: state.UserEdit.done,
    editUserLoading: state.UserEdit.updateLoading,
    editUserDone: state.UserEdit.updateDone,
    editUserError: state.UserEdit.error,
    whoIAm: state.Profile.user,
    abilities: state.SoftskillsAbilitiesList.abilities,
    userPositionDone: state.SoftskillAbilityUserPositionEdit.done,
    removeDone: state.SoftskillAbilityUserRemove.done,
    profile: state.Profile.user,
    progress: state.SoftskillsUserProgressList.progress,
  }))

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getUser(userId))
      dispatch(getSoftskillsUserProgress(userId))
    }
    dispatch(getSoftskillsAbilities())
  }, [userId])

  useEffect(() => {
    let progressPrev = []
    progress.map((item, index) => {
      const found = progressPrev.find(value => {
        return value.abilityId === item.id_habilidad
      })
      if (!found) {
        const element = {
          abilityId: item.id_habilidad,
          abilityName: item.nombre,
          statusId: item.id_status,
          courses: [
            {
              courseId: item.id_curso,
              courseName: item.nombre_curso,
              progress: item.iniciado === 0 ? 0 : item.finalizado === 0 ? 1 : 2,
            },
          ],
        }
        progressPrev.push(element)
      } else {
        const element = {
          ...found,
          courses: [
            ...found.courses,
            {
              courseId: item.id_curso,
              courseName: item.nombre_curso,
              progress: item.iniciado === 0 ? 0 : item.finalizado === 0 ? 1 : 2,
            },
          ],
        }
        const index = progressPrev.findIndex(value => {
          return value.abilityId === item.id_habilidad
        })
        progressPrev[index] = element
      }
    })
    setAbilityProgress(progressPrev)
  }, [progress])

  useEffect(() => {
    if (user !== undefined) {
      const orderedLessons = user.abilities.sort((a, b) => {
        if (!a.order || !b.order) return 1
        return a.order < b.order ? -1 : 1
      })
      setData(orderedLessons)
    }
    return () => {
      setData([])
    }
  }, [user])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]

      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setData(modifiedData)

      handleUpdatePosition(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  const handleUpdatePosition = modifiedData => {
    let positions = modifiedData.map((ability, index) => {
      return { position: index + 1, abilityId: ability.abilityId }
    })

    const request = {
      userId: parseInt(userId),
      abilityPositions: positions,
    }

    dispatch(editSoftskillAbilityUserPosition(request))
  }

  const handleRemoveAbility = () => {
    dispatch(removeSoftskillAbilityUser({ userId, abilityId: removeAbility }))
    setRemoveAbility(0)
  }

  if (userPositionDone) {
    dispatch(editSoftskillAbilityUserPositionClean())
    dispatch(getUser(userId))
  }

  if (removeDone) {
    dispatch(removeSoftskillAbilityUserClean())
    dispatch(getUser(userId))
    dispatch(getSoftskillsUserProgress(userId))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Detalle de usuario | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Detalle de usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "users",
                    title: "Usuarios",
                    link: "/soft-skills/adm-users",
                  },
                  { key: "user-detail", title: "Detalle de usuario" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Row>
                      <Col>
                        <h2>
                          Información de la asignación de habilidades del
                          usuario
                        </h2>
                        <p>Editar la información solicitada</p>
                      </Col>
                    </Row>
                    <hr />
                    {user !== undefined && (
                      <Row>
                        <Col md={{ size: 6 }} sm={{ size: 12 }}>
                          <SoftskillsProfileBox
                            user={user}
                            updateInfo={() => {
                              dispatch(getUser(userId))
                            }}
                          />
                        </Col>
                        <Col md={{ size: 6 }} sm={{ size: 12 }}>
                          <h4 className="text-center">Avance de habilidades</h4>
                          <Row>
                            <Col sm={6} xs={12}>
                              {(abilityProgress || []).map((ability, index) => {
                                const half = abilityProgress.length / 2
                                if (index > half) {
                                  return (
                                    <Fragment
                                      key={`frg-${ability.abilityId}`}
                                    ></Fragment>
                                  )
                                }
                                return (
                                  <div
                                    key={`hab-${ability.abilityId}`}
                                    className="mb-2"
                                  >
                                    {getIndicator(ability.statusId)}
                                    {index + 1}. {ability.abilityName}
                                  </div>
                                )
                              })}
                            </Col>
                            <Col sm={6} xs={12}>
                              {(abilityProgress || []).map((ability, index) => {
                                const half = abilityProgress.length / 2
                                if (index <= half) {
                                  return (
                                    <Fragment
                                      key={`frg-${ability.abilityId}`}
                                    ></Fragment>
                                  )
                                }
                                return (
                                  <div
                                    key={`hab-${ability.abilityId}`}
                                    className="mb-2"
                                  >
                                    {getIndicator(ability.statusId)}
                                    {index + 1}. {ability.abilityName}
                                  </div>
                                )
                              })}
                            </Col>
                          </Row>
                        </Col>
                        <div className="mb-2 mt-2">
                          <hr />
                        </div>
                        <Col md={6}>
                          <h3 className="text-danger">
                            Habilidades asignadas y su orden de progreso para el
                            usuario
                          </h3>
                          <hr />
                          <div>
                            <button
                              type="button"
                              className="btn btn-rounded btn-primary"
                              onClick={() => setShowAddAbilitiesModal(true)}
                            >
                              <i className="fa fa-plus-circle"></i> Agregar
                              habilidad
                            </button>
                          </div>
                          <br />
                          <ReactDragListView {...dragProps}>
                            <table className="table table-bordered table-hover">
                              <tbody>
                                {user.abilities.length < 1 && (
                                  <tr>
                                    <td>
                                      -- No se han asignado habilidades --
                                    </td>
                                  </tr>
                                )}
                                {(data || [])
                                  .sort((a, b) =>
                                    a.abilityName > b.abilityName ? -11 : 1
                                  )
                                  .map(ability => (
                                    <tr key={ability.abilityId}>
                                      <td
                                        style={{ width: "50px" }}
                                        className="text-center"
                                      >
                                        <i className="fa fa-grip-vertical"></i>
                                      </td>
                                      <td>{ability.ability.abilityName}</td>
                                      <td
                                        style={{ width: "50px" }}
                                        className="text-center"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          onClick={() => {
                                            setRemoveAbility(ability.abilityId)
                                          }}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </ReactDragListView>
                        </Col>
                        <Col md={6}>
                          <h3 className="text-danger">
                            Cursos correspondientes a la habilidad seleccionada
                          </h3>
                          {user.abilities.length < 1 && (
                            <div className="text-center">
                              -- No se han asignado habilidades --
                            </div>
                          )}
                          <div className="accordion" id="accordionExample">
                            {user.abilities.map(ability => (
                              <Fragment key={ability.abilityId}>
                                <div
                                  className="accordion-item"
                                  onClick={() => {
                                    const newAbility =
                                      ability.abilityId === showAbilityDetail
                                        ? 0
                                        : ability.abilityId
                                    setShowAbilityDetail(newAbility)
                                  }}
                                >
                                  <h2
                                    className="accordion-header"
                                    id={`heading-${ability.abilityId}`}
                                  >
                                    <button
                                      className={`accordion-button ${
                                        ability.abilityId === showAbilityDetail
                                          ? ""
                                          : "collapsed"
                                      }`}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse-${ability.abilityId}`}
                                      aria-expanded={
                                        ability.abilityId === showAbilityDetail
                                      }
                                      aria-controls={`collapse-${ability.abilityId}`}
                                    >
                                      {ability.ability.abilityName}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapse-${ability.abilityId}`}
                                    className={`accordion-collapse collapse ${
                                      ability.abilityId === showAbilityDetail
                                        ? "show"
                                        : ""
                                    }`}
                                    aria-labelledby={`heading-${ability.abilityId}`}
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <ul className="mb-0 ml-0">
                                        {ability.ability.courses.map(course => {
                                          if (course.trash === 1) return <></>
                                          return (
                                            <li key={course.courseId}>
                                              {course.courseName}
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {showAddAbilitiesModal && (
        <AbilitiesForUserModal
          onClose={() => setShowAddAbilitiesModal(false)}
          user={user}
          userId={whoIAm.idUsuario}
        />
      )}
      {removeAbility !== 0 && (
        <SweetAlert
          title={`Remover habilidad`}
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handleRemoveAbility}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setRemoveAbility(0)}
        >
          <p>¿Confirmas que deseas remover la habilidad para este usuario?</p>
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

export default SoftskillsUserDetail
