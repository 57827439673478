import { Fragment, useEffect, useState } from "react"
import { Col, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { updateSoftskillsUser, updateSoftskillsUserClean } from "store/actions"

const EditUserModal = props => {
  const user = props.user

  const dispatch = useDispatch()

  const { loadingUpdate, doneUpdate } = useSelector(state => ({
    loadingUpdate: state.SoftskillsUserUpdate.loading,
    doneUpdate: state.SoftskillsUserUpdate.done,
  }))

  const [profile, setProfile] = useState(0)
  const [position, setPosition] = useState(0)
  const [region, setRegion] = useState(0)
  const [formError, setFormError] = useState(false)

  const profileOptions = [
    { label: "S1 - Admin", value: 1 },
    { label: "S2 - Head of Sales", value: 2 },
    { label: "S3 - Regional Sales Manager", value: 3 },
    { label: "S4 - Learner", value: 4 },
  ]

  const positionOptions = [
    { label: "Key account executive", value: 4 },
    { label: "Key account manager", value: 5 },
    { label: "Regional sales manager", value: 2 },
    { label: "Sales excelence manager", value: 1 },
    { label: "Technical sales advisor", value: 3 },
    { label: "Otro", value: 6 },
  ]

  const regionOptions = [
    { label: "Admin plataforma", value: 1 },
    { label: "Bajío", value: 5 },
    { label: "Centro", value: 6 },
    { label: "Especificaciones", value: 4 },
    { label: "National", value: 2 },
    { label: "Negocios institucionales", value: 3 },
    { label: "Norte", value: 7 },
    { label: "Sureste", value: 8 },
  ]

  const profileOptionGroup = [
    {
      label: "Perfil",
      options: profileOptions,
    },
  ]

  const positionOptionGroup = [
    {
      label: "Posición",
      options: positionOptions,
    },
  ]

  const regionOptionGroup = [
    {
      label: "Región",
      options: regionOptions,
    },
  ]

  const handleProfileSelected = event => {
    setProfile(event)
  }

  const handlePositionSelected = event => {
    setPosition(event)
  }

  const handleRegionSelected = event => {
    setRegion(event)
  }

  useEffect(() => {
    if (user.softskillsProfileId) {
      setProfile(profileOptions.find(p => p.value === user.softskillsProfileId))
    }
    if (user.softskillsPositionId) {
      setPosition(
        positionOptions.find(p => p.value === user.softskillsPositionId)
      )
    }
    if (user.softskillsRegionId) {
      setRegion(regionOptions.find(p => p.value === user.softskillsRegionId))
    }
  }, [user])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    if (profile === 0 || position === 0 || region === 0) {
      setFormError(true)
      return
    }

    setFormError(false)
    const request = {
      userId: user.userId,
      profileId: profile.value,
      regionId: region.value,
      positionId: position.value,
    }

    dispatch(updateSoftskillsUser(request))
  }

  if (doneUpdate) {
    dispatch(updateSoftskillsUserClean())
    props.onConfirm()
  }

  return (
    <Fragment>
      <Modal isOpen={true} backdrop={"static"} size="md" id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title">Editar usuario</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onClose()
            }}
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="form-horizontal" onSubmit={handleSubmit}>
          <div className="modal-body">
            {formError && (
              <div className="alert alert-danger">
                Por favor ingrese todos los campos del formulario.
              </div>
            )}
            <Row className="mb-3">
              <Col md={3}>
                <Label className="col-form-label" for="name">
                  * Perfil:
                </Label>
              </Col>
              <Col md={9}>
                <Select
                  value={profile}
                  isMulti={false}
                  onChange={event => {
                    handleProfileSelected(event)
                  }}
                  options={profileOptionGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={3}>
                <Label className="col-form-label" for="name">
                  * Región:
                </Label>
              </Col>
              <Col md={9}>
                <Select
                  value={region}
                  isMulti={false}
                  onChange={event => {
                    handleRegionSelected(event)
                  }}
                  options={regionOptionGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={3}>
                <Label className="col-form-label" for="name">
                  * Posición:
                </Label>
              </Col>
              <Col md={9}>
                <Select
                  value={position}
                  isMulti={false}
                  onChange={event => {
                    handlePositionSelected(event)
                  }}
                  options={positionOptionGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                props.onClose()
              }}
            >
              Cerrar
            </button>
            <button type="submit" className="btn btn-primary">
              {loadingUpdate ? <span>Guardando...</span> : <span>Guardar</span>}
            </button>
          </div>
        </AvForm>
      </Modal>
    </Fragment>
  )
}

EditUserModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  user: PropTypes.object,
}

export default EditUserModal
