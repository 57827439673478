import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import ProfileNewSaga from "./auth/profile-new/saga"
import sessionExtendSaga from "./auth/extend/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"

// Users
import usersListSaga from "./users/list/saga"
import userAddSaga from "./users/add/saga"
import userEditSaga from "./users/edit/saga"

// Profiles
import profilesListSaga from "./profiles/saga"

// Customers
import customersListSaga from "./customers/list/saga"
import customerAddSaga from "./customers/add/saga"
import customerEditSaga from "./customers/edit/saga"
import customerGetSaga from "./customers/get/saga"

// Groups
import groupsListSaga from "./groups/list/saga"
import groupAddSaga from "./groups/add/saga"
import groupGetSaga from "./groups/get/saga"
import groupEditSaga from "./groups/edit/saga"
import groupParticipantAddSaga from "./groups/add_participant/saga"
import groupParticipantsListSaga from "./groups/get_participants/saga"
import groupParticipantRemoveSaga from "./groups/remove_participant/saga"
import groupCourseAddSaga from "./groups/add_course/saga"
import groupCoursesListSaga from "./groups/get_courses/saga"
import groupCourseRemoveSaga from "./groups/remove_course/saga"
import groupCourseResetSaga from "./groups/reset_course/saga"
import groupParticipantCourseResetSaga from "./groups/reset_participant_course/saga"
import groupUserResetSaga from "./groups/reset_user/saga"

// Positions
import positionsListSaga from "./positions/list/saga"
import positionAddSaga from "./positions/add/saga"
import positionEditSaga from "./positions/edit/saga"

// Businesses
import businessesListSaga from "./businesses/list/saga"
import businessAddSaga from "./businesses/add/saga"
import businessEditSaga from "./businesses/edit/saga"
import businessGetSaga from "./businesses/get/saga"

// Support material types
import supportMaterialTypesListSaga from "./support-material-types/list/saga"
import supportMaterialTypeAddSaga from "./support-material-types/add/saga"
import supportMaterialTypeEditSaga from "./support-material-types/edit/saga"
import supportMaterialTypeGetSaga from "./support-material-types/get/saga"

// Knowledge categories
import knowledgeCategoriesListSaga from "./knowledge-categories/list/saga"
import knowledgeCategoryAddSaga from "./knowledge-categories/add/saga"
import knowledgeCategoryEditSaga from "./knowledge-categories/edit/saga"

// Skills
import skillsListSaga from "./skills/list/saga"
import skillAddSaga from "./skills/add/saga"
import skillEditSaga from "./skills/edit/saga"

// Levels
import levelsListSaga from "./levels/list/saga"
import levelAddSaga from "./levels/add/saga"
import levelEditSaga from "./levels/edit/saga"

// Course knowledges
import courseKnowledgesListSaga from "./course-knowledges/list/saga"
import courseKnowledgeAddSaga from "./course-knowledges/add/saga"
import courseKnowledgeEditSaga from "./course-knowledges/edit/saga"

// Courses
import coursesSaga from "./courses/list/saga"
import courseGetSaga from "./courses/get/saga"
import courseAddSaga from "./courses/add/saga"
import courseEditSaga from "./courses/edit/saga"
import courseStartSaga from "./courses/start/saga"

// Lessons
import lessonsSaga from "./lessons/list/saga"
import lessonGetSaga from "./lessons/get/saga"
import lessonAddSaga from "./lessons/add/saga"
import lessonEditSaga from "./lessons/edit/saga"
import lessonRemoveSaga from "./lessons/remove/saga"
import lessonStartSaga from "./lessons/start/saga"
import lessonFinishSaga from "./lessons/finish/saga"
import lessonProgressSaga from "./lessons/progress/saga"

// Blocks
import blocksSaga from "./blocks/list/saga"
//import blockGetSaga from "./blocks/get/saga"
import blockAddSaga from "./blocks/add/saga"
import blockEditSaga from "./blocks/edit/saga"
import blockRemoveSaga from "./blocks/remove/saga"

// Support materials
import supportMaterialsListSaga from "./support-materials/list/saga"
import supportMaterialAddSaga from "./support-materials/add/saga"
import supportMaterialRemoveSaga from "./support-materials/remove/saga"

// Shoppnig Areas
import shoppingAreasListSaga from "./shopping-areas/list/saga"
import shoppingAreasAddSaga from "./shopping-areas/add/saga"
import shoppingAreasEditSaga from "./shopping-areas/edit/saga"

// Quizzes
import quizzListSaga from "./quizzes/list/saga"
import quizzAddSaga from "./quizzes/add/saga"
import quizzEditSaga from "./quizzes/edit/saga"
import quizzRemoveSaga from "./quizzes/remove/saga"
import quizzAnswersListSaga from "./quizzes/list_answer/saga"
import quizzAnswerAddSaga from "./quizzes/add_answer/saga"
import quizzAnswerEditSaga from "./quizzes/edit_answer/saga"
import quizzAnswerRemoveSaga from "./quizzes/remove_answer/saga"
import quizzAnswerUserAddSaga from "./quizzes/add_answer_user/saga"
import quizzAnswerUserGetSaga from "./quizzes/get_answer_user/saga"

// Exams
import examAddSaga from "./exams/add/saga"
import examGetSaga from "./exams/get/saga"
import examEditSaga from "./exams/edit/saga"
import examTrySaga from "./exams/try/saga"

// Questions
import questionAddSaga from "./questions/add/saga"
import questionEditSaga from "./questions/edit/saga"
import questionGetSaga from "./questions/get/saga"
import questionsListSaga from "./questions/list/saga"
import questionRemoveSaga from "./questions/remove/saga"

// Answers
import answersAddSaga from "./answers/add/saga"
import answerEditSaga from "./answers/edit/saga"
import answersListSaga from "./answers/list/saga"
import answerGetSaga from "./answers/get/saga"

// Poll
import pollsListSaga from "./polls/list/saga"
import pollAddSaga from "./polls/add/saga"
import pollEditSaga from "./polls/edit/saga"
import pollTrySaga from "./polls/try/saga"

// Poll answers
import pollAnswerAddSaga from "./poll_answers/add/saga"
import pollAnswerEditSaga from "./poll_answers/edit/saga"
import pollAnswerListSaga from "./poll_answers/list/saga"
import pollAnswerRemoveSaga from "./poll_answers/remove/saga"

// Notes
import notesListSaga from "./notes/list/saga"
import noteAddSaga from "./notes/add/saga"
import noteRemoveSaga from "./notes/remove/saga"

// Images
import imageAddSaga from "./images/add/saga"

// Favs
import favAddSaga from "./favs/add/saga"
import favRemoveSaga from "./favs/remove/saga"

// Reports
import report1ListSaga from "./reports/list_report1/saga"
import report2ListSaga from "./reports/list_report2/saga"
import report3ListSaga from "./reports/list_report3/saga"
import report4ListSaga from "./reports/list_report4/saga"
import report5ListSaga from "./reports/list_report5/saga"
import report6ListSaga from "./reports/list_report6/saga"
import reportAListSaga from "./reports/list_reporta/saga"
import reportBListSaga from "./reports/list_reportb/saga"
import report9ListSaga from "./reports/list_report9/saga"
import reportSurveyListSaga from "./reports/list_report_survey/saga"

// New requests
import abilitiesListSaga from "./abilities/list/saga"

// Interfester
import locationsSaga from "./locations/list/saga"
import allEventsListSaga from "./events/list_all/saga"
import createEventSaga from "./events/create/saga"
import updateEventSaga from "./events/update/saga"
import deleteEventSaga from "./events/delete/saga"

// Soft skills abilities
import softskillsAbilitiesListSaga from "./softskills_abilities/list/saga"
import softskillsAbilitiesAddSaga from "./softskills_abilities/add/saga"
import softskillsAbilitiesEditSaga from "./softskills_abilities/edit/saga"
import softskillsAbilitiesGetSaga from "./softskills_abilities/get/saga"
import softskillsAbilityUserAddSaga from "./softskills_abilities/add_user/saga"
import softskillsAbilityUserRemoveSaga from "./softskills_abilities/remove_user/saga"
import softskillAbilityEditUserPositionSaga from "./softskills_abilities/edit_user_position/saga"
import softskillsAbilitiesUsersAllSaga from "./softskills_abilities/all_users/saga"

// Soft skills courses
import softskillsCoursesListSaga from "./softskills_courses/list/saga"
import softskillsCoursesAddSaga from "./softskills_courses/add/saga"
import softskillsCoursesEditSaga from "./softskills_courses/edit/saga"
import softskillsCoursesGetSaga from "./softskills_courses/get/saga"
import softskillCourseTrashSaga from "./softskills_courses/trash/saga"
import softskillCoursePublishSaga from "./softskills_courses/publish/saga"
import softskillCourseUserReviewSaga from "./softskills_courses/review/saga"

// Soft skills users
import softskillsUsersAllSaga from "./softskills_users/all/saga"

// Soft skills user courses
import softskillsUserCoursesListSaga from "./softskills_users/list/saga"
import softskillsUserCourseStartSaga from "./softskills_users/start/saga"
import softskillsUserCourseFinishSaga from "./softskills_users/finish/saga"
import softskillsUserProgressListSaga from "./softskills_users/progress/saga"
import softskillsUserUpdateSaga from "./softskills_users/update/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(ProfileNewSaga),
    fork(ForgetSaga),
    fork(sessionExtendSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(usersListSaga),
    fork(userAddSaga),
    fork(userEditSaga),
    fork(profilesListSaga),
    fork(customersListSaga),
    fork(customerAddSaga),
    fork(customerEditSaga),
    fork(customerGetSaga),
    fork(groupsListSaga),
    fork(groupAddSaga),
    fork(groupGetSaga),
    fork(groupEditSaga),
    fork(groupParticipantAddSaga),
    fork(groupParticipantsListSaga),
    fork(groupParticipantRemoveSaga),
    fork(groupCourseAddSaga),
    fork(groupCoursesListSaga),
    fork(groupCourseRemoveSaga),
    fork(groupCourseResetSaga),
    fork(groupParticipantCourseResetSaga),
    fork(groupUserResetSaga),
    fork(positionsListSaga),
    fork(positionAddSaga),
    fork(positionEditSaga),
    fork(businessesListSaga),
    fork(businessAddSaga),
    fork(businessEditSaga),
    fork(businessGetSaga),
    fork(supportMaterialTypesListSaga),
    fork(supportMaterialTypeAddSaga),
    fork(supportMaterialTypeEditSaga),
    fork(supportMaterialTypeGetSaga),
    fork(shoppingAreasListSaga),
    fork(shoppingAreasAddSaga),
    fork(shoppingAreasEditSaga),
    fork(coursesSaga),
    fork(courseGetSaga),
    fork(courseAddSaga),
    fork(courseStartSaga),
    fork(courseEditSaga),
    fork(lessonsSaga),
    fork(lessonGetSaga),
    fork(lessonAddSaga),
    fork(lessonEditSaga),
    fork(lessonRemoveSaga),
    fork(lessonStartSaga),
    fork(lessonFinishSaga),
    fork(lessonProgressSaga),
    fork(blocksSaga),
    fork(blockAddSaga),
    fork(blockEditSaga),
    fork(blockRemoveSaga),
    fork(supportMaterialsListSaga),
    fork(supportMaterialAddSaga),
    fork(supportMaterialRemoveSaga),
    fork(knowledgeCategoriesListSaga),
    fork(knowledgeCategoryAddSaga),
    fork(knowledgeCategoryEditSaga),
    fork(skillsListSaga),
    fork(skillAddSaga),
    fork(skillEditSaga),
    fork(levelsListSaga),
    fork(levelAddSaga),
    fork(levelEditSaga),
    fork(courseKnowledgesListSaga),
    fork(courseKnowledgeAddSaga),
    fork(courseKnowledgeEditSaga),
    fork(imageAddSaga),
    fork(quizzListSaga),
    fork(quizzAddSaga),
    fork(quizzEditSaga),
    fork(quizzRemoveSaga),
    fork(quizzAnswersListSaga),
    fork(quizzAnswerAddSaga),
    fork(quizzAnswerEditSaga),
    fork(quizzAnswerRemoveSaga),
    fork(quizzAnswerUserAddSaga),
    fork(quizzAnswerUserGetSaga),
    fork(examAddSaga),
    fork(examGetSaga),
    fork(examEditSaga),
    fork(examTrySaga),
    fork(questionAddSaga),
    fork(questionEditSaga),
    fork(questionGetSaga),
    fork(questionsListSaga),
    fork(questionRemoveSaga),
    fork(answersAddSaga),
    fork(answerEditSaga),
    fork(answersListSaga),
    fork(answerGetSaga),
    fork(favAddSaga),
    fork(favRemoveSaga),
    fork(pollsListSaga),
    fork(pollAddSaga),
    fork(pollEditSaga),
    fork(pollTrySaga),
    fork(pollAnswerAddSaga),
    fork(pollAnswerEditSaga),
    fork(pollAnswerListSaga),
    fork(pollAnswerRemoveSaga),
    fork(notesListSaga),
    fork(noteAddSaga),
    fork(noteRemoveSaga),
    fork(report1ListSaga),
    fork(report2ListSaga),
    fork(report3ListSaga),
    fork(report4ListSaga),
    fork(report5ListSaga),
    fork(report6ListSaga),
    fork(reportAListSaga),
    fork(reportBListSaga),
    fork(report9ListSaga),
    fork(reportSurveyListSaga),
    fork(abilitiesListSaga),
    fork(locationsSaga),
    fork(allEventsListSaga),
    fork(createEventSaga),
    fork(updateEventSaga),
    fork(deleteEventSaga),
    fork(softskillsAbilitiesListSaga),
    fork(softskillsAbilitiesAddSaga),
    fork(softskillsAbilitiesEditSaga),
    fork(softskillsAbilitiesGetSaga),
    fork(softskillsAbilityUserAddSaga),
    fork(softskillsAbilityUserRemoveSaga),
    fork(softskillsAbilitiesUsersAllSaga),
    fork(softskillsCoursesListSaga),
    fork(softskillsCoursesAddSaga),
    fork(softskillsCoursesEditSaga),
    fork(softskillsCoursesGetSaga),
    fork(softskillCourseTrashSaga),
    fork(softskillCoursePublishSaga),
    fork(softskillAbilityEditUserPositionSaga),
    fork(softskillsUsersAllSaga),
    fork(softskillsUserCoursesListSaga),
    fork(softskillsUserCourseStartSaga),
    fork(softskillsUserCourseFinishSaga),
    fork(softskillsUserProgressListSaga),
    fork(softskillsUserUpdateSaga),
    fork(softskillCourseUserReviewSaga),
  ])
}
